import { memo, useCallback, useEffect, useState } from 'react'

import { ReactComponent as EmojiNotHelpful } from 'assets/icon-feedback-not-helpful.svg'
import { ReactComponent as EmojiHelpful } from 'assets/icon-feedback-helpful.svg'

import { Button, Box, makeStyles } from '@material-ui/core'

import clsx from 'clsx'

import { getPlaybookReactions, logToAnalytics, setUserReaction } from 'modules'
import { useAuth, UseBooleanType } from 'hooks'

const useStyles = makeStyles(theme => ({
    icon: {
        width: '20px',
        height: '20px',
        cursor: 'pointer',
        color: '#C1BEC1',
        transition: 'transform 0.2s ease 0s',
        transform: 'translateY(2px)'
    },
    activeIcon: {
        color: '#ffca28'
    },
    label: {
        color: '#7f8c9a',
        lineHeight: 1,
        marginBottom: theme.spacing(2)
    },
    iconContainer: {
        color: ({ color }: any) => color,
        justifyContent: 'flex-start',
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        fontSize: '12px',
        '&:hover svg': {
            color: '#ffca28',
            transform: 'translateY(-1px)'
        }
    },
    disabled: {
        color: ({ color }: any) => `${color} !important`,
        backgroundColor: `transparent !important`,
        '&:hover': {
            backgroundColor: `transparent !important`
        }
    }
}))

export const notHelpfulReaction = 1
export const helpfulReaction = 2
export const minimalRatingToShow = 50

export const negativeAnswersList = [
    'Not what I was looking for',
    'The quality of the video was bad',
    "It's not up to date"
]

type Props = {
    showReactions: UseBooleanType
    playbookId: string
    showFinishStep: UseBooleanType
    color: 'white' | 'black'
}

export const VideoReactions = memo(
    ({ showReactions, playbookId, showFinishStep, color = 'white' }: Props) => {
        const classes = useStyles({ color })

        const [reaction, setReaction] = useState(0)

        const { user } = useAuth()

        const getReaction = useCallback(() => {
            getPlaybookReactions(user?.uid || '', playbookId, setReaction)
        }, [user?.uid, playbookId])

        useEffect(() => {
            getReaction()
        }, [getReaction])

        const handleClick = () => {
            setUserReaction({
                uid: user?.uid,
                playbookId,
                reaction: helpfulReaction
            }).then(() => {
                showFinishStep.setTrue()
                logToAnalytics('video_reaction_sent', {
                    playbookId,
                    userUid: user?.uid,
                    reaction: 'happy'
                })
            })
        }

        const isActive = (value: number) => reaction === value

        return (
            <Box display="flex" flexWrap="nowrap">
                <Button
                    variant="text"
                    className={classes.iconContainer}
                    onClick={handleClick}
                    disabled={isActive(helpfulReaction)}
                    classes={{
                        disabled: classes.disabled
                    }}
                >
                    <EmojiHelpful
                        className={clsx(classes.icon, {
                            [classes.activeIcon]: isActive(helpfulReaction)
                        })}
                    />
                    Helpful
                </Button>
                <Button
                    variant="text"
                    className={classes.iconContainer}
                    onClick={showReactions.setTrue}
                    disabled={isActive(notHelpfulReaction)}
                    classes={{
                        disabled: classes.disabled
                    }}
                >
                    <EmojiNotHelpful
                        className={clsx(classes.icon, {
                            [classes.activeIcon]: isActive(notHelpfulReaction)
                        })}
                    />
                    Not helpful
                </Button>
            </Box>
        )
    }
)
