import { memo, useMemo } from 'react'

import { VideoOverlay } from '@guidde-co/shared.video-player.video-overlay'
import { PlaybookFeedback } from '@guidde-co/shared.playbook-feedback'

import { AnyPlaybookType, VideoOverlayDataType, VideoRefType } from 'app/types'

import {
    useBoolean,
    UseBooleanType,
    useClipboard,
    useNotification,
    useWindowView
} from 'hooks'

import {
    generateUrlWithDomain,
    logToAnalytics,
    openLink,
    request
} from 'modules'

import {
    generatePlaybookURL,
    onShareDialogOpenCallback,
    ShareDialog
} from './ShareDialog'
import { links } from 'app/links'
import { generateUtmParams } from './ActionButton'
import { VideoOverlayContainer } from './VideoOverlayContainer'

const source = 'embed'
type Props = {
    showVideoOverlay: UseBooleanType
    overlayData: VideoOverlayDataType
    videoRef: VideoRefType
    playbook: AnyPlaybookType
    watchLabel?: string
    onWatchClick?: () => void
}

export const VideoOverlayWrapper = memo(
    ({
        showVideoOverlay,
        videoRef,
        overlayData,
        playbook,
        onWatchClick,
        watchLabel = 'Watch Again'
    }: Props) => {
        const feedbackLoading = useBoolean()

        const shareDialog = useBoolean()
        const isClipboardSupported = useClipboard()
        const { showErrorNotification, showSuccessNotification } =
            useNotification()

        const { xsDown } = useWindowView()

        const showFeedback = useBoolean(true)

        const $sendFeedback = (comment: string) => {
            feedbackLoading.setTrue()

            return request(
                '/b/v1/send-comment',
                'POST',
                JSON.stringify({
                    playbookId: playbook.id,
                    playbookURL: generatePlaybookURL(playbook),
                    comment
                })
            )
                .catch(e => {
                    console.error('[EMBED SEND FEEDBACK]:', e)
                    showErrorNotification(
                        'Something went wrong, please try later'
                    )
                })
                .finally(feedbackLoading.setFalse)
        }

        const startPlaying = () => {
            if (!videoRef.current) return

            const ref = videoRef.current
            showVideoOverlay.setFalse()
            videoRef.current.currentTime = 0

            const play = () => {
                showFeedback.setTrue()
                ref.play()
                ref.removeEventListener('canplaythrough', play)
            }

            ref.addEventListener('canplaythrough', play)
        }

        const utmParams = useMemo(() => {
            const query = new URLSearchParams()

            const params = generateUtmParams({
                playbookId: playbook.id,
                uploadedByOrgId: playbook.uploadedByOrgId,
                isRestricted: false
            })

            Object.entries(params).map(([key, value]) =>
                query.append(key, value)
            )

            return query.toString()
        }, [playbook])

        const openSignUpPage = () => {
            const url = generateUrlWithDomain('/signup?' + utmParams)

            openLink(url)
        }

        const buttonColor = overlayData?.brandKit?.color

        const onSingUpClick = (feedback: string) => {
            logToAnalytics('post_comment_anonymouslyX', {
                feedback,
                source
            })
            openSignUpPage()
            $sendFeedback(feedback)
        }

        if (showVideoOverlay.isFalse) return null

        return (
            <VideoOverlayContainer>
                {showFeedback.isTrue &&
                !overlayData?.hideNewPublicEmbedEnding ? (
                    <PlaybookFeedback
                        isLoading={feedbackLoading.isTrue}
                        onClose={showFeedback.setFalse}
                        authorName={playbook.uploadedBy?.displayName || ''}
                        maxWidth={xsDown ? undefined : '450px'}
                        authorPhotoURL={playbook.uploadedBy?.photoURL}
                        background={buttonColor}
                        onPostClick={feedback => {
                            logToAnalytics('post_comment_clicked', {
                                feedback,
                                source
                            })
                        }}
                        onSingUpClick={onSingUpClick}
                        onPostAnonymously={async feedback => {
                            logToAnalytics('post_comment_anonymously', {
                                feedback,
                                source
                            })

                            return await $sendFeedback(feedback)
                        }}
                        hidePolicyAndTermsLink={true}
                        termsLink={links.terms}
                        privacyPolicyLink={links.privacyPolicy}
                        isMobileView={xsDown}
                    />
                ) : (
                    <VideoOverlay
                        isVisible={showVideoOverlay.isTrue}
                        onWatch={() => {
                            startPlaying()
                            onWatchClick?.()
                        }}
                        shareLabel="SHARE"
                        watchLabel={watchLabel}
                        onStartTrialClick={
                            overlayData?.showEndingScreenFooter
                                ? openSignUpPage
                                : undefined
                        }
                        onShare={
                            overlayData?.hideButtonShareInEmbed
                                ? undefined
                                : () => {
                                      shareDialog.setTrue()

                                      if (!isClipboardSupported) return

                                      onShareDialogOpenCallback(playbook).then(
                                          () => {
                                              showSuccessNotification(
                                                  'Link copied to the clipboard'
                                              )
                                          }
                                      )
                                  }
                        }
                        icon={
                            <img
                                src={overlayData?.brandKit.logo}
                                style={{
                                    height: 80,
                                    width: 'auto',
                                    objectFit: 'cover'
                                }}
                            />
                        }
                        buttonColor={buttonColor}
                    />
                )}

                <ShareDialog
                    isOpen={shareDialog.isTrue}
                    playbook={playbook}
                    onClose={shareDialog.setFalse}
                    height={Number(videoRef?.current?.clientHeight)}
                />
            </VideoOverlayContainer>
        )
    }
)
