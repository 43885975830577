import { memo, useState, useContext, useEffect } from 'react'
import { detectIncognito } from 'detectincognitojs'

import { Box } from '@material-ui/core'

import { PlaylistContext } from 'pages/playlist/PagePlaylist'
import {
    VideoPlayer,
    VideoOverlayWrapper,
    CallToActionWrapper,
    checkToOpenPlaybookPauseScreen,
    PlaybookPauseScreenWrapper
} from 'components'

import {
    logToAnalytics,
    playbookToAnalyticsProps,
    sendWatchedNotification
} from 'modules'
import { useBoolean, useQueryParams, useAuth } from 'hooks'

import { PlaybookType, QuickGuiddeType, VideoOverlayDataType } from 'app/types'

type Props = {
    playlist: PlaybookType
    playbook: PlaybookType | QuickGuiddeType
    overlayData: VideoOverlayDataType
}

export const VideoPanel = memo(({ playlist, playbook, overlayData }: Props) => {
    const showVideoOverlay = useBoolean()
    const hoverState = useBoolean()

    const [currentTime, setCurrentTime] = useState(0)

    const origin = useQueryParams('origin')

    const { user } = useAuth()

    const { videoRef, activePlaybook, setActivePlaybook, playbookList } =
        useContext(PlaylistContext)

    const playlistAutoPlay = useBoolean(true)
    const showedPauseScreen = useBoolean()
    const resetShowedPauseScreen = showedPauseScreen.setFalse
    useEffect(
        () => resetShowedPauseScreen(),
        [activePlaybook, resetShowedPauseScreen]
    )

    const playlistId = playlist.id
    const playbookId = playbook.id

    useEffect(() => {
        if (playlist) {
            logToAnalytics('open_playlist', {
                'playlist-app':
                    playlist.applications?.[0]?.applicationName || null,
                'playlist-appId':
                    playlist.applications?.[0]?.applicationId || null,
                'playlist-tags': playlist.tags,
                'playlist-id': playlistId,
                'playlist-title': playlist.title
            })
        }
    }, [playlist, playlistId])

    // Send email notification to the video owner every time when someone watches playbook
    useEffect(() => {
        if (currentTime < 5) return

        detectIncognito().then(({ isPrivate }) => {
            // do not try do to something if user use Incognito mode
            if (isPrivate) return

            if (user?.uid && playlist?.playbookList?.includes(playbookId)) {
                const viewKey = `playbookViews/${user.uid}/${playbookId}`

                // Session storage is not available in incognito mode
                try {
                    const isViewed = Boolean(window?.sessionStorage?.[viewKey])

                    if (isViewed) return
                } catch (e) {
                    console.error(e)
                    return
                }

                sendWatchedNotification('_ANONYMOUS_ORG_', user.uid, playbookId)
                logToAnalytics('videoPlay', {
                    origin: origin || '',
                    ...playbookToAnalyticsProps(playbook)
                })

                try {
                    if (window?.sessionStorage?.getItem(viewKey)) return

                    window?.sessionStorage?.setItem?.(
                        viewKey,
                        String(Date.now())
                    )
                } catch (e) {
                    console.error(e)
                }
            }
        })
    }, [
        currentTime,
        playbookId,
        playlistId,
        playlist.playbookList,
        user?.uid,
        playbook,
        origin
    ])

    const isLastPlaybookActive = activePlaybook === playbookList.length - 1

    const playlistAutoPlayIsEnabled =
        playlistAutoPlay.isTrue &&
        !isLastPlaybookActive &&
        videoRef.currentTime !== playbook.duration

    const playNextPlaylistVideo = () => {
        showVideoOverlay.setFalse()

        setActivePlaybook(isLastPlaybookActive ? 0 : activePlaybook + 1)

        videoRef.current.currentTime = 0
    }

    if (!playbook) return null

    return (
        <Box position="relative">
            <VideoPlayer
                ref={videoRef}
                hideReactions
                hoverState={hoverState}
                playbook={playbook}
                playlistAutoPlay={
                    playbookList.length > 1 ? playlistAutoPlay : undefined
                }
                hideWatermark={showVideoOverlay.isTrue}
                playlist={playlist}
                showGButtonAsIcon={overlayData.showGButtonAsIconInEmbed}
                onTimeUpdate={setCurrentTime}
                onPlay={() => {
                    logToAnalytics('videoPlayClicked', {
                        ...playbookToAnalyticsProps(playbook),
                        'playlist-id': playlistId,
                        'playlist-title': playlist.title
                    })
                }}
                onPause={() => {
                    if (playlistAutoPlayIsEnabled) return

                    logToAnalytics('videoStopClicked', {
                        ...playbookToAnalyticsProps(playbook),
                        'playlist-id': playlistId,
                        'playlist-title': playlist.title
                    })
                    checkToOpenPlaybookPauseScreen({
                        videoRef,
                        overlayData,
                        openPauseScreen: showedPauseScreen.setTrue,
                        isPauseScreenShowed: showedPauseScreen.isTrue
                    })
                }}
                onEnded={() => {
                    if (playlistAutoPlay.isTrue) {
                        playNextPlaylistVideo()
                    } else {
                        showVideoOverlay.setTrue()
                    }
                }}
            />

            {showedPauseScreen.isTrue && (
                <PlaybookPauseScreenWrapper playbook={playbook} />
            )}

            {playlistAutoPlay.isFalse && (
                <>
                    <CallToActionWrapper
                        playbook={playbook}
                        videoElement={videoRef.current}
                        onLastStepSkip={
                            !overlayData?.hideNewPublicEmbedEnding
                                ? showVideoOverlay.setTrue
                                : undefined
                        }
                    />

                    <VideoOverlayWrapper
                        showVideoOverlay={showVideoOverlay}
                        videoRef={videoRef}
                        playbook={playbook}
                        overlayData={overlayData}
                        onWatchClick={() => {
                            hoverState.setTrue()

                            if (isLastPlaybookActive) {
                                setActivePlaybook(0)
                                showVideoOverlay.setFalse()
                            } else {
                                setActivePlaybook(activePlaybook + 1)
                            }
                        }}
                        watchLabel={
                            isLastPlaybookActive
                                ? 'Watch Again'
                                : 'Watch next video'
                        }
                    />
                </>
            )}
        </Box>
    )
})
