import { createContext, useRef, useState } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import clsx from 'clsx'

import { Box, Grid, makeStyles, Typography } from '@material-ui/core'

import PageNotFound from 'pages/PageNotFound'

import { VideoPanel } from './VideoPanel'
import { SidePanel } from './SidePanel'

import { usePlaybookAuth, useWindowView, useQuery } from 'hooks'

import { CtaPlaybook, PlaybookType, VideoOverlayDataType } from 'app/types'
import { VideoLoadingSkeleton } from 'components'

export const PlaylistContext = createContext<any>({})

const useStyles = makeStyles({
    fullHeight: {
        height: '100%'
    },
    videoPanelContainer: {
        position: 'sticky',
        top: 0
    }
})

const PagePlaylist = () => {
    const classes = useStyles()
    const location = useLocation<{ data: CtaPlaybook }>()

    const videoRef = useRef()
    const { mdDown } = useWindowView()

    const { playlistId } = useParams<{ playlistId?: string }>()

    const [activePlaybook, setActivePlaybook] = useState<number>(0)

    const queryParams = new URLSearchParams(location.search)

    const dark = queryParams.get('dark')

    const { data, isValidating, error } = useQuery<{
        playbook: PlaybookType
        videos: Array<PlaybookType>
        data: VideoOverlayDataType
    }>(
        `/c/v1/quickguidde?id=${playlistId}&forEmbed=true`,
        {
            method: 'GET'
        },
        {
            onError: usePlaybookAuth().onError
        }
    )

    const currentPlaybook = data?.videos?.[activePlaybook]

    const contextValue = {
        activePlaybook,
        setActivePlaybook,
        videoRef,
        playbookList: data?.videos
    }

    if (isValidating && dark) {
        return (
            <Box
                width="100vw"
                height="100vh"
                style={{ backgroundColor: 'black' }}
            />
        )
    }

    if (!data && isValidating) return <VideoLoadingSkeleton />

    if (error || !data?.data || !currentPlaybook) {
        return (
            <PageNotFound
                isPlaylist={true}
                playbookId={playlistId}
                utmContent={error?.utmContent}
            />
        )
    }

    if (data?.playbook.playbookList?.length === 0) {
        return (
            <Typography>
                Current playlist is empty. Please add video first.
            </Typography>
        )
    }

    return (
        <PlaylistContext.Provider value={contextValue}>
            <Grid container className={classes.fullHeight}>
                <Grid
                    item
                    xs={mdDown ? 12 : 8}
                    className={clsx(
                        classes.videoPanelContainer,
                        classes.fullHeight
                    )}
                    style={{
                        backgroundColor: dark ? 'black' : 'white',
                        width: '100vw',
                        height: '100vh'
                    }}
                >
                    <Box className={classes.fullHeight}>
                        <VideoPanel
                            playbook={currentPlaybook}
                            playlist={data.playbook}
                            overlayData={data?.data}
                        />
                    </Box>
                </Grid>

                {!mdDown && (
                    <Grid item xs={12} md={4} className={classes.fullHeight}>
                        <Box height="100%">
                            <SidePanel
                                videoRef={videoRef}
                                playbook={currentPlaybook}
                                playlist={data}
                            />
                        </Box>
                    </Grid>
                )}
            </Grid>
        </PlaylistContext.Provider>
    )
}

export default PagePlaylist
