import { forwardRef } from 'react'

import { useBoolean } from 'hooks'
import { PlaylistPrevNextButtons, ShareButton, ShareDialog } from 'components'

import { VideoPlayerProps, getPlayerStatus } from './VideoPlayer'
import { Metadata } from './Metadata'

import { Box, makeStyles } from '@material-ui/core'
import { PlayControls } from '@guidde-co/shared.video-player.play-controls'

const useStyles = makeStyles({
    playControls: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        zIndex: 4
    },
    video: {
        height: '100%',
        overflow: 'hidden',
        zIndex: 1,
        objectFit: 'cover',
        position: 'relative'
    },
    metadata: {
        position: 'relative',
        zIndex: 2
    },
    shareButton: {
        position: 'absolute',
        height: 60,
        top: 0,
        right: 0
    },
    prevNextButtons: {
        zIndex: 3,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    }
})

export const MobileVideoPlayer = forwardRef<
    HTMLVideoElement | null,
    VideoPlayerProps
>(
    (
        {
            playlist,
            playbook,
            poster,
            animationUrl,
            publicData,
            onVideoContainerClick,
            status,
            setStatus,
            onEnded,
            ...videoProps
        }: any,
        ref: any
    ) => {
        const classes = useStyles()

        const shareDialog = useBoolean()

        if (!playbook.url) return null

        return (
            <Box
                position="relative"
                overflow="hidden"
                borderRadius={4}
                height={Number(ref?.current?.clientHeight)}
            >
                {status !== 'playing' &&
                    !publicData?.hideButtonShareInEmbed && (
                        <Box zIndex={3} className={classes.shareButton} p={3}>
                            <ShareButton
                                playbook={playlist || playbook}
                                onclick={shareDialog.setTrue}
                            />
                        </Box>
                    )}
                <video
                    crossOrigin="anonymous"
                    ref={ref}
                    className={classes.video}
                    width="100%"
                    height="auto"
                    src={playbook.url}
                    poster={animationUrl || poster}
                    controls={status !== 'stopped'}
                    muted={videoProps?.autoPlay}
                    autoPlay={videoProps?.autoPlay}
                    onPlay={() => {
                        videoProps?.onPlay()
                        setStatus('playing')
                    }}
                    onPause={() => {
                        videoProps?.onPause()
                        setStatus('stopped')
                    }}
                    onSeeking={() => setStatus('loading')}
                    onLoadedData={(e: any) => setStatus(getPlayerStatus(e))}
                    onSeeked={(e: any) => setStatus(getPlayerStatus(e))}
                    onEnded={() => {
                        setStatus('stopped')
                        onEnded?.()
                    }}
                    preload="auto"
                >
                    {playbook.subtitlesUrl && publicData?.showCaptions && (
                        <track
                            label="English"
                            kind="subtitles"
                            srcLang="en"
                            src={playbook.subtitlesUrl}
                            default
                        />
                    )}
                </video>
                <Box className={classes.playControls}>
                    <PlayControls
                        onClick={() => {
                            if (status === 'playing') {
                                ref.current.pause()
                                setStatus('stopped')
                                return
                            }
                            ref.current.play()
                            setStatus('playing')
                        }}
                        color={playlist ? 'transparent' : 'white'}
                        visible={
                            (status !== 'playing' && !playlist) ||
                            (playlist && status === 'stopped')
                        }
                        status={status === 'playing' ? 'playing' : 'stopped'}
                    />
                </Box>
                <Box className={classes.metadata}>
                    <Metadata
                        showMetadata={status !== 'playing'}
                        playbook={playbook}
                        isMobile={true}
                    />
                </Box>

                <ShareDialog
                    isOpen={shareDialog.isTrue}
                    onClose={shareDialog.setFalse}
                    playbook={playbook}
                    height={Number(ref?.current?.clientHeight)}
                />
                {playlist && status !== 'playing' && (
                    <Box className={classes.prevNextButtons}>
                        <PlaylistPrevNextButtons spacing={12} videoRef={ref} />
                    </Box>
                )}
            </Box>
        )
    }
)
