import firebase from 'firebase'

import { SignContent } from '@guidde-co/shared.sign-content'

import { Box } from '@material-ui/core'

import * as Sentry from '@sentry/react'

import { generateUrlWithDomain, logToAnalytics } from 'modules'
import { useAuth } from 'hooks'

import { links } from 'app/links'

export type SignInProvider = 'google.com' | 'microsoft.com'

export const LoginPage = () => {
    const { setLoginPage } = useAuth()

    const continueWithPopup = (source: SignInProvider) => {
        const provider = new firebase.auth.OAuthProvider(source)

        firebase
            .auth()
            .signInWithPopup(provider)
            .then(() => setLoginPage(false))
            .catch(console.error)
    }

    const getAuthUrl = async (domain: string) => {
        const url = generateUrlWithDomain(`/auth/v1/sso/auth?domain=${domain}`)

        const response = await fetch(url)
        return response.json()
    }

    const onWindowMessage = async (e: any) => {
        if (e.data) {
            try {
                await firebase
                    .auth()
                    .signInWithCustomToken(e.data.token)
                    .then(data => {
                        logToAnalytics('sso-login', {
                            isNewUser: data.additionalUserInfo?.isNewUser,
                            providerId: data.additionalUserInfo?.providerId,
                            email: data.user?.email,
                            id: data.user?.uid
                        })
                    })
            } catch (e) {
                Sentry.captureException(new Error('sso-login-failed'), {
                    extra: {
                        props: (e as any).data,
                        error: e
                    }
                })
            }
        }
    }

    return (
        <Box
            maxHeight="100vh"
            height="100%"
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <SignContent
                termsLink={links.terms}
                privacyPolicyLink={links.privacyPolicy}
                title="Log in"
                analyticKey="sign-in"
                label="LOG IN"
                initialTab={1}
                mobileMaxWidth={390}
                googleLabel="Continue with Google"
                microsoftLabel="Continue with Microsoft"
                emailValue=""
                decreaseMargins={2}
                isMobileView={true}
                isLoading={false}
                onGoogleClick={() => continueWithPopup('google.com')}
                onMicrosoftClick={() => continueWithPopup('microsoft.com')}
                onSubmit={({ domain, setSubmitting, setSSOError }) => {
                    getAuthUrl(domain).then(res => {
                        if (res?.authUrl) {
                            // specify the properties of the popup window
                            const popupWindow = window.open(
                                res?.authUrl,
                                'Popup',
                                'width=600,height=800'
                            )

                            window.addEventListener('message', onWindowMessage)

                            if (popupWindow) popupWindow.focus()
                        } else {
                            setSSOError()
                            setSubmitting(false)
                        }
                    })
                }}
            />
        </Box>
    )
}
